import * as React from 'react'
import dynamic from 'next/dynamic'

import { SubscriptionInfoBox } from '@thg-commerce/enterprise-components/Product/src/SubscriptionInfoBox'
import {
  ProductVariations,
  SubscriptionPaymentType,
} from '@thg-commerce/enterprise-components/ProductVariations'
import { useI18n } from '@thg-commerce/enterprise-core'
import { SubscriptionChoice } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product/ProductPage'
import { ProductVariantWithDescription } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import { SubscriptionContract } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { PurchaseOptions as PurchaseOptionsInterface } from '@thg-commerce/enterprise-pages/src/Product/theme'
import { spacing } from '@thg-commerce/enterprise-theme'
import { SelectedOptions, Tabs } from '@thg-commerce/gravity-patterns'

import { SubscriptionFrequencyDropDown } from '../../SubscriptionFrequencyDropDown'

import { StyledIcon, TabsContainer } from './style'

const SvgAccountSubscriptionsOutline = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/AccountIcons/AccountSubscriptionsOutline'
    ),
)

interface PurchaseOptionsProps {
  isSubscription?: boolean
  productVariants?: ProductVariantWithDescription[]
  subscriptionContracts?: SubscriptionContract[]
  subscribeOptions?: {
    upfront: SubscriptionChoice[]
    onDispatch: SubscriptionChoice[]
  }
  selectedOptions?: SelectedOptions
  onOptionChange?: (key: string, value: string) => void
  purchaseOptionsStyle?: PurchaseOptionsInterface
  showSavingsAmountOnSubscriptionCard?: boolean
  selectedFrequencyId?: string
  setSelectedFrequencyId?: (state: string) => void
  setActiveTabIndex?: (state: number) => void
  activeTabIndex?: number
  subscriptionPaymentType?: SubscriptionPaymentType | null
  isQuickBuy?: boolean
  enableSubscribeAndSaveInlinePricing?: boolean
  displaysPrices?: {
    oneTimePurchaseDisplayPrice?: string
    subscriptionDisplayPrice?: string
  }
}

export const PurchaseOptions = ({
  isSubscription,
  subscribeOptions,
  selectedOptions,
  onOptionChange,
  subscriptionContracts,
  purchaseOptionsStyle,
  showSavingsAmountOnSubscriptionCard,
  setActiveTabIndex,
  activeTabIndex,
  selectedFrequencyId,
  setSelectedFrequencyId,
  isQuickBuy,
  enableSubscribeAndSaveInlinePricing,
  displaysPrices,
}: PurchaseOptionsProps) => {
  const i18n = useI18n()
  const i18nText = {
    purchaseOption1: i18n(
      isSubscription
        ? 'product.purchaseoptions.payupfront.text'
        : 'product.purchaseoptions.onetimepurchase.text',
    ),
    purchaseOption2: i18n(
      isSubscription
        ? 'product.purchaseoptions.payperiodically.text'
        : 'product.purchaseoptions.subscribeandgain.text',
    ),
    subscriptionInfo: {
      subscriptionTitle: i18n('product.subscription.information.title'),
      subscriptionListItem1: i18n(
        'product.subscription.information.list.text1',
      ),
      subscriptionListItem2: i18n(
        'product.subscription.information.list.text2',
      ),
      subscriptionListItem3: i18n(
        'product.subscription.information.list.text3',
      ),
    },
    dropDownTitle: i18n(
      'product.subscriptions.subscriptionfrequencyselector.title',
    ),
  }

  const availableFrequencies = subscriptionContracts?.map((contract) => {
    const { duration, unit } = contract.frequencyDuration || {
      duration: '',
      unit: '',
    }
    const displayValue = duration && unit ? `${duration} ${unit}` : ''

    return {
      key: contract.id,
      displayText: displayValue,
      value: displayValue,
    }
  })

  return (
    <TabsContainer
      subscriptionTabsTheme={purchaseOptionsStyle}
      enableSubscribeAndSaveInlinePricing={enableSubscribeAndSaveInlinePricing}
    >
      <Tabs
        setActiveTabIndex={setActiveTabIndex}
        tabIndex={activeTabIndex}
        disableTabKey={true}
        showInactiveBg={true}
        enableSubscribeAndSaveInlinePricing={
          enableSubscribeAndSaveInlinePricing
        }
        tabs={[
          ...((isSubscription && subscribeOptions?.upfront.length) ||
          !isSubscription
            ? [
                {
                  title: i18nText.purchaseOption1,
                  displayPrice: displaysPrices?.oneTimePurchaseDisplayPrice,
                  content: isSubscription ? (
                    <ProductVariations
                      selectedOptions={selectedOptions}
                      onOptionChange={onOptionChange}
                      subscriptionChoices={subscribeOptions?.upfront || []}
                      showSavingsAmountOnSubscriptionCard={
                        showSavingsAmountOnSubscriptionCard
                      }
                      subscriptionChoicesPrefixId={'upfront'}
                    />
                  ) : (
                    <React.Fragment />
                  ),
                },
              ]
            : []),
          ...((isSubscription && subscribeOptions?.onDispatch.length) ||
          !isSubscription
            ? [
                {
                  title: i18nText.purchaseOption2,
                  displayPrice: displaysPrices?.subscriptionDisplayPrice,
                  icon: purchaseOptionsStyle?.subscribeAndGainIcon && (
                    <StyledIcon
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={
                        purchaseOptionsStyle.subscribeAndGainIcon.viewBox
                      }
                      width={purchaseOptionsStyle.subscribeAndGainIcon.width}
                      height={purchaseOptionsStyle.subscribeAndGainIcon.height}
                      fill={
                        purchaseOptionsStyle.subscribeAndGainIcon.styling.fill
                      }
                      focus={
                        purchaseOptionsStyle.subscribeAndGainIcon.styling.focus
                          .fill
                      }
                    >
                      <path
                        d={purchaseOptionsStyle.subscribeAndGainIcon.path}
                      />
                    </StyledIcon>
                  ),
                  content: isSubscription ? (
                    <ProductVariations
                      selectedOptions={selectedOptions}
                      onOptionChange={onOptionChange}
                      subscriptionChoices={subscribeOptions?.onDispatch || []}
                      showSavingsAmountOnSubscriptionCard={
                        showSavingsAmountOnSubscriptionCard
                      }
                      subscriptionChoicesPrefixId={'onDispatch'}
                    />
                  ) : (
                    <SubscriptionInfoBox
                      infoText={{
                        upsellText:
                          subscriptionContracts && subscriptionContracts.length
                            ? subscriptionContracts[0].upsellMessage
                            : '',
                        infoTextList: [
                          i18nText.subscriptionInfo.subscriptionListItem1,
                          i18nText.subscriptionInfo.subscriptionListItem2,
                          i18nText.subscriptionInfo.subscriptionListItem3,
                        ],
                      }}
                      title={{
                        text: i18nText.subscriptionInfo.subscriptionTitle,
                        icon: <SvgAccountSubscriptionsOutline />,
                      }}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
      {!isSubscription && activeTabIndex === 1 && (
        <div style={{ marginBottom: `${spacing(2)}` }}>
          <SubscriptionFrequencyDropDown
            title={i18nText.dropDownTitle}
            onChangeValue={setSelectedFrequencyId}
            contractValue={selectedFrequencyId}
            availableFrequencies={availableFrequencies}
            useDefaultDropdownWidth={isQuickBuy}
            width={isQuickBuy ? '' : '100%'}
          />
        </div>
      )}
    </TabsContainer>
  )
}
